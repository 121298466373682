import React, { lazy } from "react";
import * as Sentry from "@sentry/react";
import { Suspense } from "./common/Suspense/Suspense";
import {
  AuthenticationProvider,
  BannerProvider,
  HotjarTracker,
  MassSelectProvider,
} from "./providers";
import { AppProvider } from "./context/AppProvider";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import {
  AdminRoute,
  AnalyticsBetaRoute,
  AnalyticsRoute,
  AuthenticatedRoute,
  BaseRoute,
  CampaignActivityRoute,
  DashboardRoute,
  DeletedLeadsRoute,
  EditLeadFormRoute,
  EditUserProfileRoute,
  ErrorPageRoute,
  FirstLoginRoute,
  HeaderRoute,
  LeadDetailsRoute,
  LeadFormsRoute,
  LeadsChasedMetricsRoute,
  NotificationsRoute,
  OauthRoute,
  PipelineHopperRoute,
  PublicRoute,
  RemindersRoute,
  SegmentsRoute,
  TeamResourcesRoute,
  UserAnalyticsRoute,
  WorkerDashboardRoute,
  WorkerRoute,
} from "./routes";
import { RootProvider } from "./providers";
import { LoginTokenCheck } from "./layouts/LoginTokenCheck";
import { OauthSetup } from "./pages/Oauth/OauthSetup/OauthSetup";
import { OauthProcessing } from "./pages/Oauth/OauthProcessing";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const DeletedLeads = lazy(() => import("./pages/DeletedLeads/DeletedLeads"));
const Analytics = lazy(() => import("./pages/Analytics/Analytics"));
const UserAnalytics = lazy(() =>
  import("./pages/Analytics/UserAnalytics/UserAnalytics")
);
const CampaignPanel = lazy(() => import("./pages/Admin/CampaignPanel/CampaignPanel"));
const TeamResources = lazy(() => import("./pages/TeamResources/TeamResources"));
const LeadDetailsWrapper = lazy(() =>
  import("./pages/Leads/LeadDetails/LeadDetailsWrapper")
);
const LeadsChasedMetrics = lazy(() =>
  import("./pages/LeadsChasedMetrics/LeadsChasedMetrics")
);
const ReminderCalendar = lazy(() =>
  import("./pages/Reminders/ReminderCalendar/ReminderCalendar")
);
const CampaignActivity = lazy(() =>
  import("./pages/CampaignActivity/CampaignActivity")
);
const Notifications = lazy(() => import("./pages/Notifications/Notifications"));
const Segments = lazy(() => import("./pages/Segments/Segments"));
const PipelineHopper = lazy(() =>
  import("./pages/PipelineHopper/PipelineHopper")
);

const EditCampaign = lazy(() =>
  import("./pages/Admin/EditCampaign/EditCampaign")
);
const EditPmNotes = lazy(() => import("./pages/Admin/EditPmNotes/EditPmNotes"));
const EditPriceSheet = lazy(() =>
  import("./pages/Admin/EditPriceSheet/EditPriceSheet")
);
const EditQuoteTemplateWrapper = lazy(() =>
  import("./pages/Admin/EditQuoteTemplate/EditQuoteTemplateWrapper")
);
const EnterprisePayouts = lazy(() =>
  import("./pages/Admin/EnterprisePayouts/EnterprisePayouts")
);
const Integrations = lazy(() =>
  import("./pages/Admin/Integrations/Integrations")
);
const PriceSheets = lazy(() => import("./pages/Admin/PriceSheets/PriceSheets"));
const QuoteTemplates = lazy(() =>
  import("./pages/Admin/QuoteTemplates/QuoteTemplates")
);
const TeamBroadcast = lazy(() =>
  import("./pages/Admin/TeamBroadcast/TeamBroadcast")
);
const Territories = lazy(() => import("./pages/Admin/Territories/Territories"));
const WebhookRequests = lazy(() =>
  import("./pages/Admin/WebhookRequests/WebhookRequests")
);
const Zapier = lazy(() => import("./pages/Admin/Integrations/Zapier/Zapier"));

const QuoteSettings = lazy(() =>
  import("./pages/Admin/QuoteSettings/QuoteSettings")
);
const ApprovalConditions = lazy(() =>
  import("./pages/Admin/ApprovalConditions/ApprovalConditions")
);
const UserListing = lazy(() => import("./pages/Admin/UserListing/UserListing"));
const UserDetails = lazy(() =>
  import("./pages/Admin/UserListing/UserDetails/UserDetails")
);
const EditUser = lazy(() =>
  import("./pages/Admin/UserListing/UserDetails/EditUser/EditUser")
);
const ScoreCard = lazy(() => import("./pages/Admin/ScoreCard/ScoreCard"));
const LeadForms = lazy(() => import("./pages/LeadForms/LeadForms"));
const EditLeadForm = lazy(() => import("./pages/EditLeadForm/EditLeadForm"));
const EditUserProfile = lazy(() =>
  import("./pages/EditUserProfile/EditUserProfile")
);
const ResetPassword = lazy(() =>
  import("./pages/Public/ResetPassword/ResetPassword")
);
const EditPassword = lazy(() =>
  import("./pages/Public/EditPassword/EditPassword")
);
const ProgramDetails = lazy(() =>
  import("./pages/Admin/ProgramDetails/ProgramDetails")
);
const FirstLogin = lazy(() =>
  import("./pages/Public/Login/FirstLogin/FirstLogin")
);
const AppLogin = lazy(() => import("./pages/Public/AppLogin/AppLogin"));
const WorkerDashboard = lazy(() =>
  import("./pages/WorkerDashboard/WorkerDashboard")
);
const GoodLead = lazy(() => import("./pages/WorkerDashboard/GoodLead"));
const ViewPayout = lazy(() =>
  import("./pages/Admin/EnterprisePayouts/ViewPayout")
);
const GeneratePayout = lazy(() =>
  import("./pages/Admin/EnterprisePayouts/GeneratePayout")
);
const Reminders = lazy(() => import("./pages/Reminders/Reminders"));
const ErrorPage = lazy(() => import("./pages/ErrorPage/ErrorPage"));

import "./App.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NavLayout from "./layouts/NavLayout/NavLayout";
import StyleProvider from "./providers/StyleProvider";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<NavLayout />}>
      <Route
        path="/sign_in"
        element={
          <PublicRoute>
            <LoginTokenCheck>
              <Suspense>
                <AppLogin />
              </Suspense>
            </LoginTokenCheck>
          </PublicRoute>
        }
      />
      <Route
        path="/password"
        element={
          <PublicRoute>
            <Suspense>
              <ResetPassword />
            </Suspense>
          </PublicRoute>
        }
      />
      <Route
        path="/users/password/edit"
        element={
          <PublicRoute>
            <Suspense>
              <EditPassword />
            </Suspense>
          </PublicRoute>
        }
      />
      <Route path="/" element={<AuthenticatedRoute />}>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <FirstLoginRoute>
                <Suspense>
                  <FirstLogin />
                </Suspense>
              </FirstLoginRoute>
            </PublicRoute>
          }
        />
        <Route
          path="oauth/:appId"
          element={
            <OauthRoute>
              <OauthSetup />
            </OauthRoute>
          }
        />
        <Route
          path="oauth/processing"
          element={
            <OauthRoute>
              <OauthProcessing />
            </OauthRoute>
          }
        />
        <Route path="" element={<HeaderRoute />}>
          <Route path="" element={<BaseRoute />}>
            <Route path="" element={<WorkerRoute />} />
            <Route
              path="/dashboard"
              element={
                <DashboardRoute>
                  <Suspense>
                    <Dashboard />
                  </Suspense>
                </DashboardRoute>
              }
            />
            <Route
              path="/activities"
              element={
                <CampaignActivityRoute>
                  <Suspense>
                    <CampaignActivity />
                  </Suspense>
                </CampaignActivityRoute>
              }
            />
            <Route
              path="/leads/:leadId"
              element={
                <LeadDetailsRoute>
                  <Suspense>
                    <LeadDetailsWrapper />
                  </Suspense>
                </LeadDetailsRoute>
              }
            />
            <Route
              path="users/:userId/edit"
              element={
                <EditUserProfileRoute>
                  <Suspense>
                    <EditUserProfile />
                  </Suspense>
                </EditUserProfileRoute>
              }
            />
            <Route
              path="users/:userId/analytics"
              element={
                <UserAnalyticsRoute>
                  <Suspense>
                    <UserAnalytics />
                  </Suspense>
                </UserAnalyticsRoute>
              }
            />
            <Route
              path="/pipeline"
              element={
                <PipelineHopperRoute>
                  <Suspense>
                    <PipelineHopper />
                  </Suspense>
                </PipelineHopperRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <NotificationsRoute>
                  <Suspense>
                    <Notifications />
                  </Suspense>
                </NotificationsRoute>
              }
            />
            <Route
              path="/segments"
              element={
                <SegmentsRoute>
                  <Suspense>
                    <Segments />
                  </Suspense>
                </SegmentsRoute>
              }
            />
            <Route
              path="/leads_chased_metrics"
              element={
                <LeadsChasedMetricsRoute>
                  <Suspense>
                    <LeadsChasedMetrics />
                  </Suspense>
                </LeadsChasedMetricsRoute>
              }
            />
            <Route
              path="/trash"
              element={
                <DeletedLeadsRoute>
                  <Suspense>
                    <DeletedLeads />
                  </Suspense>
                </DeletedLeadsRoute>
              }
            />
            <Route
              path="/frontline"
              element={
                <WorkerDashboardRoute>
                  <Suspense>
                    <WorkerDashboard />
                  </Suspense>
                </WorkerDashboardRoute>
              }
            />
            <Route
              path="/goodLead"
              element={
                <Suspense>
                  <GoodLead />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="reminders/calendar"
            element={
              <Suspense>
                <ReminderCalendar />
              </Suspense>
            }
          />
          <Route
            path="users/:userId/reminders"
            element={
              <RemindersRoute>
                <MassSelectProvider>
                  <Suspense>
                    <Reminders />
                  </Suspense>
                </MassSelectProvider>
              </RemindersRoute>
            }
          />
          <Route
            path="/campaigns/:campaignId/analytics"
            element={<AnalyticsRoute />}
          >
            <Route
              path=""
              element={
                <Suspense>
                  <Analytics />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/campaigns/:campaignId/team-resources/*"
            element={
              <TeamResourcesRoute>
                <Suspense>
                  <TeamResources />
                </Suspense>
              </TeamResourcesRoute>
            }
          />
          <Route
            path="/campaigns/:campaignId/new-analytics"
            element={
              <AnalyticsBetaRoute>
                <Suspense>
                  <Analytics />
                </Suspense>
              </AnalyticsBetaRoute>
            }
          />
          <Route path="/admin/campaigns/:campaignId" element={<AdminRoute />}>
            <Route
              path=""
              element={
                <Suspense>
                  <CampaignPanel />
                </Suspense>
              }
            />
            <Route
              path="edit"
              element={
                <Suspense>
                  <EditCampaign />
                </Suspense>
              }
            />
            <Route path="enterprise_payouts">
              <Route
                path=""
                element={
                  <Suspense>
                    <EnterprisePayouts />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <ViewPayout />
                  </Suspense>
                }
              />
              <Route
                path="new"
                element={
                  <Suspense>
                    <GeneratePayout />
                  </Suspense>
                }
              />
            </Route>
            <Route path="lead_forms" element={<LeadFormsRoute />}>
              <Route
                path=""
                element={
                  <Suspense>
                    <LeadForms />
                  </Suspense>
                }
              />
              <Route
                path=":leadFormId"
                element={
                  <EditLeadFormRoute>
                    <Suspense>
                      <EditLeadForm />
                    </Suspense>
                  </EditLeadFormRoute>
                }
              />
            </Route>
            <Route
              path="lead_pages"
              element={
                <Suspense>
                  <ProgramDetails />
                </Suspense>
              }
            />
            <Route
              path="pm_notes"
              element={
                <Suspense>
                  <EditPmNotes />
                </Suspense>
              }
            />
            <Route
              path="territories"
              element={
                <Suspense>
                  <Territories />
                </Suspense>
              }
            />
            <Route path="price_sheets">
              <Route
                path=":id/edit"
                element={
                  <Suspense>
                    <EditPriceSheet />
                  </Suspense>
                }
              />
              <Route
                path=""
                element={
                  <Suspense>
                    <PriceSheets />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="quote_settings"
              element={
                <Suspense>
                  <QuoteSettings />
                </Suspense>
              }
            />
            <Route
              path="approval_conditions"
              element={
                <Suspense>
                  <ApprovalConditions />
                </Suspense>
              }
            />
            <Route path="quote_templates">
              <Route
                path=":quoteTemplateId"
                element={
                  <Suspense>
                    <EditQuoteTemplateWrapper />
                  </Suspense>
                }
              />
              <Route
                path=""
                element={
                  <Suspense>
                    <QuoteTemplates />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="mail/new"
              element={
                <Suspense>
                  <TeamBroadcast />
                </Suspense>
              }
            />
            <Route
              path="score_card"
              element={
                <Suspense>
                  <ScoreCard />
                </Suspense>
              }
            />
            <Route
              path="integrations"
              element={
                <Suspense>
                  <Integrations />
                </Suspense>
              }
            />
            <Route path="users">
              <Route
                path=":userId"
                element={
                  <Suspense>
                    <UserDetails />
                  </Suspense>
                }
              />
              <Route
                path=":userId/edit"
                element={
                  <Suspense>
                    <EditUser />
                  </Suspense>
                }
              />
              <Route
                path=""
                element={
                  <Suspense>
                    <UserListing />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="webhook_requests"
              element={
                <Suspense>
                  <WebhookRequests />
                </Suspense>
              }
            />
            <Route
              path="zapier"
              element={
                <Suspense>
                  <Zapier />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Route>
      <Route path="error">
        <Route
          path="404"
          element={
            <ErrorPageRoute>
              <Suspense>
                <ErrorPage errorCode="404" />
              </Suspense>
            </ErrorPageRoute>
          }
        />
        <Route
          path="403"
          element={
            <ErrorPageRoute>
              <Suspense>
                <ErrorPage errorCode="403" />
              </Suspense>
            </ErrorPageRoute>
          }
        />
        <Route
          path="500"
          element={
            <ErrorPageRoute>
              <Suspense>
                <ErrorPage errorCode="500" />
              </Suspense>
            </ErrorPageRoute>
          }
        />
        <Route
          path=""
          element={
            <ErrorPageRoute>
              <Suspense>
                <ErrorPage />
              </Suspense>
            </ErrorPageRoute>
          }
        />
      </Route>

      <Route
        path="*"
        exact={true}
        element={
          <ErrorPageRoute>
            <Suspense>
              <ErrorPage errorCode="404" />
            </Suspense>
          </ErrorPageRoute>
        }
      />
    </Route>
  )
);

export default function App() {
  return (
    <StyleProvider>
      <BannerProvider>
        <HotjarTracker />
        <div id="appLayer">
          <AuthenticationProvider>
            <DndProvider backend={HTML5Backend}>
              <AppProvider>
                <RootProvider>
                  <RouterProvider router={router} />
                </RootProvider>
              </AppProvider>
            </DndProvider>
          </AuthenticationProvider>
        </div>
      </BannerProvider>
    </StyleProvider>
  );
}
