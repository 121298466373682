import React from "react";
import { Dashboard as ToolkitDashboard } from "@wingmate/graphs";
import { ICellMapping, IDashboard } from "../../../types/IDashboard";
import { Cell } from "../Cell/Cell";
import { Filters } from "../Filters/Filters";
import { DashboardIcon } from "../DashboardIcon/DashboardIcon";

import "./Dashboard.scss";

export interface IDashboardProps {
  dashboard: IDashboard;
}

export const Dashboard: React.FC<IDashboardProps> = ({ dashboard }) => {
  const {
    columnCount, icon, iconColor, id, mapping, name, rowCount,
  } = dashboard.attributes;
  return (
    <div data-testid="Dashboard">
      <ToolkitDashboard
        columnCount={columnCount}
        icon={<DashboardIcon iconName={icon} />}
        iconColor={iconColor}
        filters={<Filters dashboardId={id} />}
        rowCount={rowCount}
        title={name}
      >
        {
              mapping.map((cellMapping: ICellMapping) => (
                <Cell
                  key={cellMapping.id}
                  id={cellMapping.id}
                  colspan={cellMapping.colspan}
                  rowspan={cellMapping.rowspan}
                  position={cellMapping.position}
                />
              ))
            }
      </ToolkitDashboard>
    </div>
  );
};
