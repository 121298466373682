import React, { useState } from "react";
import { CloseIcon, SearchIcon } from "@wingmate/graphics";
import classNames from "classnames";
import {
  Button, TextField, TextFieldProps, IFieldPayload,
} from "@wingmate/toolkit";

import "./SearchField.scss";

export interface ISearchFieldProps extends Omit<TextFieldProps, "InputProps"> {
  leftIcon?: React.ReactNode;
}

export const SearchField: React.FC<ISearchFieldProps> = ({
  className,
  "data-cy": dataCy = "SearchField",
  "data-testid": dataTestId = "SearchField",
  id,
  leftIcon,
  onChange,
  value,
  ...restProps
}) => {
  const [searchText, setSearchText] = useState(value);

  const handleClear = () => {
    setSearchText("");
    onChange?.({ id, value: "" });
  };

  const handleChange = (payload: IFieldPayload<string | number>) => {
    setSearchText(payload.value);
    onChange?.(payload);
  };

  return (
    <TextField
      className={classNames("SearchField", className)}
      data-cy={dataCy}
      data-testid={dataTestId}
      id={id}
      InputProps={{
        startAdornment: leftIcon === undefined ? (
          <SearchIcon
            className="SearchField__SearchIcon"
            data-testid="SearchField__SearchIcon"
            width="16px"
            height="16px"
          />
        ) : leftIcon,
        endAdornment: searchText && (
          <Button onClick={handleClear} size="small">
            <CloseIcon data-testid="SearchField__ClearIcon" width={14} height={14} />
          </Button>
        ),
      }}
      value={searchText}
      onChange={handleChange}
      {...restProps}
    />
  );
};
