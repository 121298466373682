import { ICurrentCampaign, ICurrentUser } from "../types/IContext";
import { Platform } from "../types/ICustomField";

export const currentUserCanAccessFreshStage = (
  user: ICurrentUser,
  campaign: ICurrentCampaign,
): boolean => {
  const { type } = user;

  return (
    type !== "Salesperson"
      || (type === "Salesperson" && campaign.salesVisibility)
  );
};

export const currentUserCanSeeAllLeads = (
  user: ICurrentUser,
  campaign: ICurrentCampaign,
): boolean => {
  const { type } = user;

  return (
    type === "Manager"
      || (type === "Salesperson" && campaign.salesVisibility)
  );
};

export const getCustomFieldPlatformsForCurrentUser = (user: ICurrentUser): Platform[] => {
  switch (user.type) {
    case "Worker":
      return [Platform.both];
    case "Salesperson":
      return [Platform.both, Platform.web];
    case "Manager":
      return [Platform.both, Platform.web, Platform.manager];
    default:
      return [];
  }
};

export default {
  currentUserCanAccessFreshStage,
  currentUserCanSeeAllLeads,
  getCustomFieldPlatformsForCurrentUser,
};
