import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import {
  Spinner, Text, TabItem, Tabs,
} from "@wingmate/toolkit";
import { IDashboard } from "../../types/IDashboard";
import { Dashboard } from "./Dashboard/Dashboard";
import { useStore } from "../../hooks/useStore";

import "./AnalyticsBeta.scss";

export const AnalyticsBeta: React.FC = observer(() => {
  const { t } = useTranslation(["default", "common"]);
  const { dashboardsStore } = useStore();

  useEffect(() => {
    dashboardsStore.retrieveCampaignDashboards();
  }, [dashboardsStore]);

  // This will eventually come from the backend.
  const getAnalyticsDashboards = (): TabItem[] => {
    const { dashboards } = dashboardsStore;

    return dashboards.map((dashboard: IDashboard) => ({
      key: dashboard.id,
      label: <Text type="H6">{dashboard.attributes.name}</Text>,
      children: (
        <Dashboard
          dashboard={dashboard}
        />
      ),
    }));
  };

  const { isLoading } = dashboardsStore;

  return (
    <div data-testid="AnalyticsBeta">
      {
        isLoading ? (
          <Spinner />
        ) : (
          <Tabs
            className="AnalyticsBeta"
            data-testid="AnalyticsBeta__Tabs"
            items={getAnalyticsDashboards()}
            title={t("missionControl")}
          />
        )
      }
    </div>
  );
});

export default AnalyticsBeta;
