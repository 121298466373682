import { action, computed, makeObservable, observable } from "mobx";
import { isNullOrEmpty } from "../utils/commons";
import { BaseStore } from "./BaseStore";
import * as activitiesService from "../requests/leads/activities";
import * as commentsService from "../requests/leads/comments";
import * as remindersService from "../requests/leads/reminders";

export const ALL_FILTER = "all";
export const UPDATES_FILTER = "updates";
export const REMINDERS_FILTER = "reminders";
export const ACTIONS_FILTER = "actions";

const CANCELLED_ERROR = "CanceledError";

export class ActivityStore extends BaseStore {
  leadDetailsStore;

  @observable activities = [];

  @observable activeFilter = ALL_FILTER;

  @observable totalCount = 0;

  @observable activityFilters = {};

  @observable isLoading = false;

  @observable order = "created_at_desc";

  @observable page = {
    current: 1,
    more: false,
    next: null,
  };

  constructor(leadDetailsStore) {
    super();

    makeObservable(this);

    this.leadDetailsStore = leadDetailsStore;
  }

  @computed get filterKeys() {
    return Object.keys(this.activityFilters);
  }

  @computed get isActivityFiltersEmpty() {
    return (
      this.filterKeys.filter(
        (filter) =>
          this.activityFilters[filter] &&
          !isNullOrEmpty(this.activityFilters[filter].toString())
      ).length === 0
    );
  }

  @action
  setActivities(activities) {
    this.activities = activities;
  }

  @action
  setActiveFilter(newFilter) {
    this.activeFilter = newFilter;
  }

  @action
  setTotalCount(totalCount) {
    this.totalCount = totalCount;
  }

  @action
  setActivityFilters(activityFilters) {
    this.activityFilters = activityFilters;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setPage(page) {
    this.page = page;
  }

  @action
  resetPage() {
    this.setPage({
      current: 1,
      more: false,
      next: null,
    });
  }

  @action
  clearAdditionalActivityFilters() {
    delete this.activityFilters.update_types;
    delete this.activityFilters.reminder_types;
    delete this.activityFilters.reminder_statuses;
  }

  @action
  addFilter(id, value) {
    this.activityFilters[id] = value;
  }

  @action
  removeFilter(id) {
    delete this.activityFilters[id];
  }

  @action
  async createCommentAsync(payload) {
    const { leadId, rootStore } = this.leadDetailsStore;

    const payloadWithLocation = {
      ...payload,
      ...rootStore.location,
    };

    this.setIsLoading(true);
    try {
      await commentsService.postLeadComment(leadId, payloadWithLocation);
      await this.retrieveActivitiesForLeadAsync();
      this.setIsLoading(false);
    } catch (err) {
      this.setIsLoading(false);
      throw err;
    }
  }

  @action
  async updateCommentAsync(commentId, payload) {
    const { leadId } = this.leadDetailsStore;
    this.setIsLoading(true);
    await commentsService.putLeadComment(leadId, commentId, payload);
    await this.retrieveActivitiesForLeadAsync();
    this.setIsLoading(false);
  }

  async deleteCommentAsync(commentId) {
    const { leadId } = this.leadDetailsStore;
    this.setIsLoading(true);
    await commentsService.deleteLeadComment(leadId, commentId);
    await this.retrieveActivitiesForLeadAsync();
    this.setIsLoading(false);
  }

  async deleteReminderAsync(reminderId) {
    this.setIsLoading(true);
    await remindersService.deleteReminder(reminderId);
    await this.retrieveActivitiesForLeadAsync();
    this.setIsLoading(false);
  }

  @action
  async updateReminderAsync(reminderId, payload) {
    this.setIsLoading(true);
    await remindersService.putReminder(reminderId, payload);
    await this.retrieveActivitiesForLeadAsync();
    this.setIsLoading(false);
  }

  @action
  async pushReminderAsync(reminderId, leadId, payload) {
    this.setIsLoading(true);
    await remindersService.putReminder(reminderId, {
      mark_cancelled: true,
    });
    await remindersService.postReminder(leadId, payload);
    await this.retrieveActivitiesForLeadAsync();
    this.setIsLoading(false);
  }

  @action
  async retrieveActivitiesForLeadAsync() {
    this.cancelPreviousRequests();

    const { leadId } = this.leadDetailsStore;

    this.setIsLoading(true);

    try {
      const { data, meta } = await activitiesService.getActivities(leadId, {
        order: this.order,
        page: 1,
        activity_type: this.activeFilter,
        ...this.activityFilters,
      }, {
        signal: this.abortController.signal,
      });
  
      this.setActivities(data);
      this.setPage(meta.page);
      this.setTotalCount(meta.count);
    } catch (error) {
      if (error.name !== CANCELLED_ERROR) {
        throw error;
      }
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async retrieveNextPageActivitiesForLeadAsync() {
    const { leadId } = this.leadDetailsStore;

    this.setIsLoading(true);

    const { data, meta } = await activitiesService.getActivities(leadId, {
      order: this.order,
      page: this.page.next,
      activity_type: this.activeFilter,
      ...this.activityFilters,
    });

    this.setActivities([...this.activities, ...data]);
    this.setPage(meta.page);
    this.setTotalCount(meta.count);

    this.setIsLoading(false);
  }
}

export default ActivityStore;
