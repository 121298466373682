export enum IconId {
  DOLLAR_GRASS = "dollar_grass",
  DOOR_AZURE = "door_azure",
  MONEY = "money",
  PARTY_SUNRISE = "party_sunrise",
  TEAM_BLUEBERRY = "team_blueberry",
  USER_AZURE = "user_azure",
}

export enum DashboardOwners {
  CAMPAIGN = "Campaign",
  COMPANY = "Company",
}

export interface ICellPosition {
  row: number;
  col: number;
}

export interface ICellMapping {
  position: ICellPosition;
  colspan: number;
  rowspan: number;
  id: string;
}

export interface IDashboardAttributes {
  columnCount: number;
  id: number | string;
  icon: IconId;
  iconColor: string;
  name: string;
  order: number;
  ownerId: number;
  ownerType: string;
  mapping: ICellMapping[];
  rowCount: number;
}

export interface IDashboard {
  id: string;
  attributes: IDashboardAttributes;
}
