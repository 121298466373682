import { ILeadStatusType } from "./ICampaign";

export type CustomFieldType = "number" | "text" | "date" | "datetime" | "multiselect" | "select" | "sic" | "naics";

export enum Platform {
  integrations = "integrations",
  web = "web",
  both = "both",
  manager = "manager",
}

export type FieldValue = string | number | string[] | null;

export interface ICustomField {
  default?: string;
  depends: string[];
  id: string;
  name: string;
  options?: string[];
  platform: Platform;
  required: string;
  required_for_status: ILeadStatusType[];
  section: string;
  type: CustomFieldType;
  ui: string;
  version?: string;
}
