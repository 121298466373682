import { IconId } from "./IDashboard";

export enum ICellType {
  AREA = "area",
  BAR = "bar",
  KPI = "kpi",
  LEADERBOARD = "leaderboard",
}

export interface IBaseCellAttributes {
  color?: string;
  icon?: IconId;
  id: string | number;
  title: string;
}

// Area Cell

export interface IAreaKey {
  key: string;
  color: string;
  fillOpacity?: number
}

export interface IAreaData {
  areaKeys: IAreaKey[];
  data: Array<Record<string, number>>;
  stacked?: boolean;
  xKey: string;
  xAxisLabel: string;
  yAxisLabel: string;
}

export interface IAreaAttributes extends IBaseCellAttributes {
  type: ICellType.AREA;
  data?: IAreaData;
}

// Bar Cell

export interface IBarData {
  data: Array<Record<string, number>>;
  stacked?: boolean;
  xKey: string;
  xAxisLabel: string;
  yAxisLabel: string;
}

export interface IBarAttributes extends IBaseCellAttributes {
  type: ICellType.BAR;
  data?: IBarData;
}

// KPI Cell

export interface IKPIAttributes extends IBaseCellAttributes {
  type: ICellType.KPI;
  data?: {
    value: number;
    trendLabel?: string;
    trendValue?: number;
    trendPercentage?: number;
  };
}

// Leaderboard Cell

export interface ILeaderboardEntry {
  imageSrc?: string;
  name: string;
  value: number;
  id: number;
}

export interface ILeaderboardData {
  data: ILeaderboardEntry[];
  entryLabel: string;
  measurementLabel: string;
}

export interface ILeaderboardAttributes extends IBaseCellAttributes {
  type: ICellType.LEADERBOARD;
  data?: ILeaderboardData;
}

export type ICellAttributes = IAreaAttributes |
IBarAttributes |
IKPIAttributes |
ILeaderboardAttributes;

export interface ICell {
  id: string;
  attributes: ICellAttributes;
}
