import React from "react";
import {
  AnalyticsDollarGrassIcon,
  AnalyticsDoorAzureIcon,
  AnalyticsPartySunriseIcon,
  AnalyticsUserAzureIcon,
  AnalyticsUsersBlueberryIcon,
} from "@wingmate/graphics";
import { IconId } from "../../../types/IDashboard";

export interface IDashboardIconProps {
  iconName: IconId;
  size?: number;
}

export const DashboardIcon: React.FC<IDashboardIconProps> = ({ iconName, size = 32 }) => {
  const sharedProps = {
    width: size,
    height: size,
  };

  switch (iconName) {
    case IconId.DOLLAR_GRASS:
      return <AnalyticsDollarGrassIcon {...sharedProps} data-testid="AnalyticsDollarGrassIcon" />;
    case IconId.DOOR_AZURE:
      return <AnalyticsDoorAzureIcon {...sharedProps} data-testid="AnalyticsDoorAzureIcon" />;
    case IconId.MONEY:
      return <AnalyticsDollarGrassIcon {...sharedProps} data-testid="AnalyticsDollarGrassIcon" />;
    case IconId.PARTY_SUNRISE:
      return <AnalyticsPartySunriseIcon {...sharedProps} data-testid="AnalyticsPartySunriseIcon" />;
    case IconId.TEAM_BLUEBERRY:
      return <AnalyticsUsersBlueberryIcon {...sharedProps} data-testid="AnalyticsUsersBlueberryIcon" />;
    case IconId.USER_AZURE:
      return <AnalyticsUserAzureIcon {...sharedProps} data-testid="AnalyticsUserAzureIcon" />;
    default:
      return <div data-testid="NoIcon" />;
  }
};
