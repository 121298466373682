import React from "react";
import { observer } from "mobx-react";
import { Navigate } from "react-router-dom";
import { AnalyticsBeta } from "../pages/AnalyticsBeta/AnalyticsBeta";
import { TranslationProvider } from "../providers/TranslationProvider";
import i18next from "../pages/Analytics/translations/i18n";
import { AppContext } from "../context/AppProvider";

export const AnalyticsBetaRoute = observer(() => {
  const { campaign, currentUser } = React.useContext(AppContext);

  if (!currentUser) return null;
  if (!campaign?.analyticsBetaEnabled) {
    return <Navigate to="/" replace />;
  }

  return (
    <TranslationProvider i18n={i18next}>
      <div className="AnalyticsBetaRoute" data-testid="AnalyticsBetaRoute">
        <AnalyticsBeta />
      </div>
    </TranslationProvider>
  );
});
export default AnalyticsBetaRoute;
