import {
  action, makeObservable, observable,
} from "mobx";
import * as globalSearchService from "../requests/globalSearch/globalSearch";
import { BaseStore } from "./BaseStore";
import { ICampaign } from "../types/ICampaign";
import { RootStore } from "./RootStore";
import { ILeadAttributes } from "../types/ILead";

export const CANCELLED_ERROR = "CanceledError";
export const MIN_CHARACTERS_FOR_SEARCH = 3;

interface ICampaignSearchResults {
  leads: ILeadAttributes[];
  page: number;
  totalHits: number;
}

export class GlobalSearchStore extends BaseStore {
  rootStore;

  @observable isSearchBarOpen = false;

  @observable isLoading = false;

  @observable searchValue = "";

  @observable results: Record<ICampaign["id"], ICampaignSearchResults> = {};

  constructor(rootStore: RootStore) {
    super();

    makeObservable(this);

    this.rootStore = rootStore;
  }

  @action
  setIsSearchBarOpen(isSearchBarOpen: boolean) {
    this.isSearchBarOpen = isSearchBarOpen;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  setSearchValue(searchValue: string) {
    this.searchValue = searchValue;
  }

  @action
  setResults(campaignId: string, leads: ILeadAttributes[], page: number, totalHits: number) {
    this.results[campaignId] = {
      leads,
      page,
      totalHits,
    };
  }

  @action
  clearResults() {
    this.results = {};
  }

  isNewSearchValue(value: string) {
    return this.searchValue !== value;
  }

  alreadyLoadedLeads(campaignId: string) {
    return this.results[campaignId]?.leads;
  }

  @action
  async searchNewLeads(campaignId: string, value: string) {
    this.cancelPreviousRequests();

    if (this.isNewSearchValue(value)) {
      this.clearResults();
      if (value.length < MIN_CHARACTERS_FOR_SEARCH) {
        this.setIsLoading(false);
        return;
      }
    } else if (this.alreadyLoadedLeads(campaignId)) {
      this.setIsLoading(false);
      return;
    }

    this.setIsLoading(true);
    this.setSearchValue(value);

    try {
      const data = await globalSearchService.searchLeads(
        this.rootStore.context.currentUser.authToken,
        campaignId,
        value,
        1,
        { signal: this.abortController.signal },
      );

      this.setResults(campaignId, data.leads, 1, data.totalHits);
      this.setIsLoading(false);
    } catch (error) {
      if (error instanceof Error && error.name === CANCELLED_ERROR) return;
      throw error;
    }
  }

  @action
  async loadMoreLeads(campaignId: string, searchText: string) {
    if (this.isLoading) return;
    this.setIsLoading(true);

    const { page } = this.results[campaignId];
    const nextPage = page + 1;

    try {
      const data = await globalSearchService.searchLeads(
        this.rootStore.context.currentUser.authToken,
        campaignId,
        searchText,
        nextPage,
        { signal: this.abortController.signal },
      );

      this.setResults(
        campaignId,
        [...this.results[campaignId].leads, ...data.leads],
        nextPage,
        data.totalHits,
      );
      this.setIsLoading(false);
    } catch (error) {
      if (error instanceof Error && error.name === CANCELLED_ERROR) return;
      throw error;
    }
  }
}

export default GlobalSearchStore;
