import * as axiosService from "../axiosRequests";
import { ICell } from "../../types/ICell";
import { endpoint } from "../../utils/endpoint";
import { DashboardInterval, IDashboardFilters } from "../../stores/DashboardsStore";

interface ICellFilterParams {
  interval: DashboardInterval;
  start_date?: Date;
  end_date?: Date;
}

export const CAMPAIGN_CELL_ENDPOINT = (campaignId: string | number, cellId: string): string => endpoint(`app/v1.0/campaigns/${campaignId}/cells/${cellId}`);

export const getCell = async (
  campaignId: string | number,
  cellId: string,
  filters: IDashboardFilters,
): Promise<ICell> => {
  const params: ICellFilterParams = {
    interval: filters.interval,
    start_date: filters.startDate,
    end_date: filters.endDate,
  };

  const response = await axiosService.axiosGet(
    CAMPAIGN_CELL_ENDPOINT(campaignId, cellId),
    params,
  );

  return response.data;
};

export default {
  getCell,
};
