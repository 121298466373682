import { computed, makeAutoObservable } from "mobx";
import { ApprovalRequestsStore } from "./ApprovalRequestsStore";
import { BaseStore } from "./BaseStore";
import { DashboardsStore } from "./DashboardsStore";
import { CampaignActivityStore } from "./CampaignActivityStore";
import { CellsStore } from "./CellsStore";
import { ContractStore } from "./ContractStore";
import { DuplicatesStore } from "./DuplicatesStore";
import { FiltersStore } from "./FiltersStore";
import { FunnelStore } from "./FunnelStore";
import { GlobalSearchStore } from "./GlobalSearchStore";
import { LeadSlideoutStore } from "./LeadSlideoutStore";
import { LeadsStore } from "./LeadsStore";
import { LeadsChasedMetricsStore } from "./LeadsChasedMetricsStore";
import { ListLeadsStore } from "./ListLeadsStore";
import { MapStore } from "./MapStore";
import { MassUpdateStore } from "./MassUpdateStore";
import { NavigationStore } from "./NavigationStore";
import { NotificationsStore } from "./NotificationsStore";
import { PerformancesStore } from "./PerformancesStore";
import { PipelineStore } from "./PipelineStore";
import { PriceSheetsStore } from "./PriceSheetsStore";
import { RemindersIndexStore } from "./RemindersIndexStore";
import { RemindersStore } from "./RemindersStore";
import { SegmentsStore } from "./SegmentsStore";
import { TableStore } from "./TableStore";
import { TasksStore } from "./TasksStore";
import { TargetsStore } from "./TargetsStore";
import { TeamFeedStore } from "./TeamFeedStore";
import { TeamResourcesStore } from "./TeamResourcesStore";
import { TerritoriesStore } from "./TerritoriesStore";
import { UsersStore } from "./UsersStore";
import { ViewStore } from "./ViewStore";

export class RootStore {
  
  authStore;
  
  approvalRequestsStore;

  cellsStore;
  
  dashboardsStore;

  funnelStore;

  leadsStore;

  listLeadsStore;

  mapStore;

  segmentsStore;

  teamResourcesStore; 

  stores = [];

  constructor(authStore) {
    this.authStore = authStore;
    this.registerStore("approvalRequestsStore", new ApprovalRequestsStore(this));
    this.registerStore("cellsStore", new CellsStore(this));
    this.registerStore("dashboardsStore", new DashboardsStore(this));
    this.registerStore("listLeadsStore", new ListLeadsStore(this));
    this.registerStore("funnelStore", new FunnelStore(this));
    this.registerStore("leadsStore", new LeadsStore(this));
    this.registerStore("mapStore", new MapStore(this));
    
    this.segmentsStore = new SegmentsStore(this);
    this.teamResourcesStore = new TeamResourcesStore(this);
    this.duplicatesStore = new DuplicatesStore(this);
    this.massUpdateStore = new MassUpdateStore(this);
    this.notificationsStore = new NotificationsStore(this);
    this.usersStore = new UsersStore(this);
    this.filtersStore = new FiltersStore(this);
    this.tableStore = new TableStore(this);
    this.viewStore = new ViewStore(this);
    this.pipelineStore = new PipelineStore(this);
    this.contractStore = new ContractStore(this);
    this.campaignActivityStore = new CampaignActivityStore(this);
    this.priceSheetsStore = new PriceSheetsStore(this);
    this.tasksStore = new TasksStore(this);
    this.targetsStore = new TargetsStore(this);
    this.territoriesStore = new TerritoriesStore(this);
    this.performancesStore = new PerformancesStore(this);
    this.remindersStore = new RemindersStore(this);
    this.remindersIndexStore = new RemindersIndexStore(this);
    this.leadsChasedMetricsStore = new LeadsChasedMetricsStore(this);
    this.teamFeedStore = new TeamFeedStore(this);
    this.latitude = null;
    this.longitude = null;
    this.locationOn = null;
    this.locationLoading = true;
    this.navigationStore = new NavigationStore(this);
    this.globalSearchStore = new GlobalSearchStore(this);
    this.leadSlideoutStore = new LeadSlideoutStore(this);

    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          this.locationOn = true;
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.locationLoading = false;
        },
        () => {
          this.locationOn = false;
          this.locationLoading = false;
        }
      );
    } else {
      this.locationLoading = false;
    }

    makeAutoObservable(this);
  }

  registerStore(storeName, store) {
    if (!(store instanceof BaseStore)) {
      throw new Error("Store must extend BaseStore");
    }

    this.stores.push(store);

    this[storeName] = store;
  }

  clear() {
    this.stores.forEach((store) => {
      if (store instanceof BaseStore) {
        store.clear();
      }
    });
  }

  @computed get context() {
    return this.authStore.context;
  }

  @computed get campaignId() {
    return this.authStore.context.campaign.id;
  }

  @computed get bannerStore() {
    return this.authStore.bannerStore;
  }

  @computed get location() {
    return {
      locationOn: this.locationOn,
      latitude: this.latitude,
      longitude: this.longitude,
    };
  }
}

export default RootStore;
