import {
  action, makeObservable, observable, override,
} from "mobx";
import { BaseStore } from "./BaseStore";
import { ICell } from "../types/ICell";
import * as arrayUtils from "../utils/arrays";
import { IRootStore } from "../types/IRootStore";
import * as campaignsCellsService from "../requests/campaigns/cells";
import { DashboardsStore } from "./DashboardsStore";

interface ITemporaryRootStoreMock {
  dashboardsStore: DashboardsStore;
}

export class CellsStore extends BaseStore {
  rootStore;

  @observable cells: ICell[] = [];

  constructor(rootStore: IRootStore) {
    super();

    makeObservable(this);

    this.rootStore = rootStore;
  }

  @action
  cacheCells(cells: ICell[]) {
    const allCells = [...cells, ...this.cells];

    const allCellsUnique = arrayUtils.uniqueOnId(allCells, "id");

    this.setCells(allCellsUnique);
  }

  @override
  clear() {
    this.cells = [];
  }

  findCellById(id: string): ICell | undefined {
    return this.cells.find((cell) => cell.id === id);
  }

  @action
  async getCampaignCellById(campaignId: string | number, id: string) {
    // TODO: Get rid of this when converting RootStore to Typescript
    const dashboardsStore = (
      this.rootStore as unknown as ITemporaryRootStoreMock
    ).dashboardsStore as DashboardsStore;

    const { filters } = dashboardsStore;

    const cell: ICell = await campaignsCellsService.getCell(campaignId, id, filters);

    this.cacheCells([cell]);
  }

  @action
  setCells(cells: ICell[]) {
    this.cells = cells;
  }
}

export default CellsStore;
