import React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import { IDashboardFilters } from "../../../stores/DashboardsStore";
import { DateFilter } from "./DateFilter/DateFilter";

interface IFiltersProps {
  dashboardId: string | number;
}

export const Filters: React.FC<IFiltersProps> = observer(({
  dashboardId,
}) => {
  const rootStore = useStore();
  const { dashboardsStore } = rootStore;
  const { filters } = dashboardsStore;

  const onChange = (newFilters: Partial<IDashboardFilters>) => {
    dashboardsStore.updateFilters(newFilters);

    dashboardsStore.reloadDashboardById(dashboardId);
  };

  return (
    <div>
      <DateFilter defaultInterval={filters.interval} onChange={onChange} />
    </div>
  );
});

export default Filters;
