import { useEffect } from "react";

export const useReloadConfirm = () => {
  const handleBeforeReload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeReload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeReload);
    };
  }, []);
};

export default useReloadConfirm;
