import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const PURCHASABLE_ENDPOINT = (priceSheetId, purchasableId) =>
  endpoint(
    `app/v1.0/price_sheets/${priceSheetId}/purchasables/${purchasableId}`
  );

const PURCHASABLES_ENDPOINT = (priceSheetId) =>
  endpoint(`app/v1.0/price_sheets/${priceSheetId}/purchasables`);

export const PURCHASABLES_CSV_ENDPOINT = (priceSheetId) =>
  endpoint(`app/v1.0/price_sheets/${priceSheetId}/purchasables.csv`);


export async function getPurchasables(priceSheetId, payload) {
  const response = await axiosService.axiosGet(
    PURCHASABLES_ENDPOINT(priceSheetId),
    payload
  );

  return response.data;
}

export async function postPurchasable(priceSheetId, purchasable) {
  const payload = {
    purchasable,
  };

  const response = await axiosService.axiosPost(
    PURCHASABLES_ENDPOINT(priceSheetId),
    payload
  );

  return response.data;
}

export async function putPurchasable(priceSheetId, purchasableId, purchasable) {
  const payload = {
    purchasable: {
      price: purchasable.price,
      minimum_price: purchasable.minimumPrice,
      name: purchasable.name,
      description: purchasable.description,
      custom_fields: purchasable.customFields,
    },
  };

  const response = await axiosService.axiosPut(
    PURCHASABLE_ENDPOINT(priceSheetId, purchasableId),
    payload
  );

  return response.data;
}

export async function deletePurchasable(priceSheetId, purchasableId) {
  const response = await axiosService.axiosDelete(
    PURCHASABLE_ENDPOINT(priceSheetId, purchasableId)
  );

  return response.data;
}

export default {
  deletePurchasable,
  postPurchasable,
  putPurchasable,
};
