import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { AxiosError } from "axios";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spinner, Text } from "@wingmate/toolkit";
import {
  AreaChart, BarChart, Cell as ToolkitCell, KeyIndicator, Leaderboard,
} from "@wingmate/graphs";
import { DashboardIcon } from "../DashboardIcon/DashboardIcon";
import { useStore } from "../../../hooks";
import { ICellPosition } from "../../../types/IDashboard";
import { ICell, ICellType } from "../../../types/ICell";

import "./Cell.scss";

export interface ICellProps {
  id: string;
  position: ICellPosition;
  colspan: number;
  rowspan: number;
}

export const Cell: React.FC<ICellProps> = observer(({
  colspan, id, position, rowspan,
}) => {
  const rootStore = useStore();
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation(["default", "common"]);

  const { bannerStore, cellsStore } = rootStore;

  const cell: ICell | undefined = cellsStore.findCellById(id);

  const {
    color, data, icon, title, type,
  } = cell?.attributes || {};

  useEffect(() => {
    const retrieveCell = async () => {
      try {
        const campaignView = location.pathname.includes("campaigns");

        // TODO: Modify this to support
        // company-level campaigns with an if else statement for company context.
        if (campaignView) {
          await cellsStore.getCampaignCellById(params.campaignId, id);
        }
      } catch (err: unknown) {
        const typedError = err as AxiosError;
        bannerStore.addBanner(
          "red",
          t("cell.error"),
          t(typedError.message),
        );
      }
    };

    retrieveCell();
  }, [params, id]); // eslint-disable-line

  const renderGraph = () => {
    if (!cell) {
      return (
        <Spinner />
      );
    }

    if (!data) {
      return (
        <Text type="O">{t("cell.renderError")}</Text>
      );
    }

    switch (type) {
      case ICellType.AREA:
        return (
          <AreaChart
            height={280}
            {...data}
          />
        );
      case ICellType.BAR:
        return (
          <BarChart
            {...data}
          />
        );
      case ICellType.KPI:
        return (
          <KeyIndicator
            {...data}
          />
        );
      case ICellType.LEADERBOARD:
        return (
          <Leaderboard
            {...data}
          />
        );
      default:
        return (<Text type="O">{t("cell.renderError")}</Text>);
    }
  };

  return (
    <ToolkitCell
      color={color}
      colspan={colspan}
      icon={icon && <DashboardIcon iconName={icon} />}
      rowspan={rowspan}
      position={position}
      title={title}
    >
      {
        renderGraph()
      }
    </ToolkitCell>
  );
});
