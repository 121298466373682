import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  DateRangeField,
  DateRangeFieldPayload,
  Dropdown,
  DropdownItemProps,
  DropdownOnClickProps,
  Text,
} from "@wingmate/toolkit";
import { DashboardInterval, IDashboardFilters } from "../../../../stores/DashboardsStore";

import "./DateFilter.scss";

export interface IDateFilterProps {
  defaultInterval: DashboardInterval;
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  onChange: (filters: Partial<IDashboardFilters>) => void;
}

const CUSTOM_INTERVAL_KEY = DashboardInterval.CUSTOM;

export const DateFilter: React.FC<IDateFilterProps> = ({
  defaultEndDate, defaultInterval, defaultStartDate, onChange,
}) => {
  const { t } = useTranslation(["default", "common"]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [interval, setInterval] = useState(defaultInterval);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const onFiltersChange = (filters: Partial<IDashboardFilters>) => {
    onChange({
      interval,
      startDate,
      endDate,
      ...filters,
    });
  };

  const onClick = (params: DropdownOnClickProps) => {
    const { key } = params;
    const newInterval = key as DashboardInterval;

    if (interval !== CUSTOM_INTERVAL_KEY) {
      setDropdownOpen(false);
      onFiltersChange({ interval: newInterval });
    }

    setInterval(newInterval);
  };

  const onCustomChange = (values: DateRangeFieldPayload) => {
    setInterval(CUSTOM_INTERVAL_KEY);
    setDropdownOpen(false);

    setStartDate(values.startDate);
    setEndDate(values.endDate);

    onFiltersChange({
      interval: CUSTOM_INTERVAL_KEY,
      ...values,
    });
  };

  const getIntervalOptions = (): DropdownItemProps[] => {
    const intervalOptions: DropdownItemProps[] = Object.values(
      DashboardInterval,
    ).map((value: string) => {
      if (DashboardInterval.CUSTOM === value) {
        return {
          key: "custom",
          label: (
            <DateRangeField
              startId="startDate"
              endId="endDate"
              placement="bottomLeft"
              onChange={onCustomChange}
            />
          ),
        };
      }

      return {
        key: value,
        label: t(`filters.interval.${value}`),
      };
    });

    return intervalOptions;
  };

  const getFilterLabel = () => {
    if (interval === CUSTOM_INTERVAL_KEY) {
      return `${moment(startDate).format("DD-MM-YYYY")} - ${moment(
        endDate,
      ).format("DD-MM-YYYY")}`;
    }

    return t(`filters.interval.${interval}`);
  };

  const toggleDropdownOpen = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const items = getIntervalOptions();

  return (
    <div>
      <Dropdown
        items={items}
        onClick={onClick}
        open={dropdownOpen}
        className="DashboardDateFilter"
        buttonProps={{
          className: "DashboardDateFilter__Button",
          onClick: toggleDropdownOpen,
          type: "filled",
          secondary: true,
        }}
      >
        <Text type="P">{getFilterLabel()}</Text>
      </Dropdown>
    </div>
  );
};
