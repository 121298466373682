import {
  action, makeObservable, observable, override,
} from "mobx";
import {
  DashboardOwners, ICellMapping, IconId, IDashboard,
} from "../types/IDashboard";
import { BaseStore } from "./BaseStore";
import { CellsStore } from "./CellsStore";
import * as arrayUtils from "../utils/arrays";
import { IRootStore } from "../types/IRootStore";

// TODO: Remove this by converting RootStore to tsx
interface ITemporaryRootStoreMock {
  cellsStore: CellsStore;
}

export enum DashboardInterval {
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  YEAR = "year",
  CUSTOM = "custom",
}

export interface IDashboardFilters {
  interval: DashboardInterval;
  startDate?: Date;
  endDate?: Date;
}

export class DashboardsStore extends BaseStore {
  rootStore;

  @observable isLoading = true;

  @observable dashboards: IDashboard[] = [];

  @observable filters: IDashboardFilters = {
    interval: DashboardInterval.MONTH,
  };

  constructor(rootStore: IRootStore) {
    super();

    makeObservable(this);

    this.rootStore = rootStore;
  }

  getDashboardsForCampaign(campaignId: string | number): IDashboard[] {
    return this.dashboards.filter((dashboard: IDashboard) => {
      const { ownerId, ownerType } = dashboard.attributes;
      const isOwnerIdEqual = ownerId.toString() === campaignId.toString();
      const isOwnerTypeCampaign = ownerType === DashboardOwners.CAMPAIGN;

      return isOwnerIdEqual && isOwnerTypeCampaign;
    });
  }

  @override
  clear() {
    this.dashboards = [];
    this.isLoading = true;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  setDashboards(dashboards: IDashboard[]) {
    this.dashboards = dashboards.sort((a, b) => a.attributes.order - b.attributes.order);
  }

  cacheDashboards(dashboards: IDashboard[]) {
    const allDashboards = [...dashboards, ...this.dashboards];

    const allDashboardsUnique = arrayUtils.uniqueOnId(allDashboards, "id");

    this.setDashboards(allDashboardsUnique);
  }

  @action
  setFilters(filters: IDashboardFilters) {
    this.filters = filters;
  }

  @action
  updateFilters(newFilters: IDashboardFilters) {
    this.filters = {
      ...this.filters,
      ...newFilters,
    };
  }

  @action
  async reloadDashboardById(dashboardId: string | number) {
    // TODO: Change this after RootStore is converted
    const cellsStore = (
      this.rootStore as unknown as ITemporaryRootStoreMock
    ).cellsStore as CellsStore;

    const dashboard: IDashboard | undefined = this.dashboards.find(
      (board: IDashboard) => board.attributes.id === dashboardId,
    );

    if (!dashboard) {
      return;
    }

    dashboard.attributes.mapping.forEach((mapping: ICellMapping) => {
      cellsStore.getCampaignCellById(this.rootStore.campaignId, mapping.id);
    });
  }

  // To be overwritten in 009: Analytics Part 2
  @action
  async retrieveCampaignDashboards() {
    const revenueDashboard = {
      id: "revenue",
      attributes: {
        id: "revenue",
        columnCount: 4,
        rowCount: 2,
        name: "Revenue",
        icon: IconId.MONEY,
        iconColor: "#2DB764",
        order: 1,
        ownerId: this.rootStore.campaignId,
        ownerType: DashboardOwners.CAMPAIGN,
        mapping: [{
          id: "kpi_average_amount",
          rowspan: 1,
          colspan: 1,
          position: { row: 1, col: 1 },
        }, {
          id: "kpi_completed_amount",
          rowspan: 1,
          colspan: 1,
          position: { row: 1, col: 2 },
        }, {
          id: "kpi_completed_count",
          rowspan: 1,
          colspan: 1,
          position: { row: 1, col: 3 },
        }, {
          id: "leaderboard_revenue",
          rowspan: 2,
          colspan: 1,
          position: { row: 1, col: 4 },
        }, {
          id: "bar_revenue_overview",
          rowspan: 1,
          colspan: 3,
          position: { row: 2, col: 1 },
        }],
      },
    };

    const engagementDashboard = {
      id: "engagement",
      attributes: {
        id: "engagement",
        columnCount: 4,
        rowCount: 2,
        name: "Engagement",
        icon: IconId.MONEY,
        iconColor: "#2DB764",
        order: 1,
        ownerId: this.rootStore.campaignId,
        ownerType: DashboardOwners.CAMPAIGN,
        mapping: [{
          id: "kpi_login_count",
          rowspan: 1,
          colspan: 1,
          position: { row: 1, col: 1 },
        }, {
          id: "kpi_lead_generation_count",
          rowspan: 1,
          colspan: 1,
          position: { row: 1, col: 2 },
        }, {
          id: "kpi_lead_conversion_count",
          rowspan: 1,
          colspan: 1,
          position: { row: 1, col: 3 },
        }, {
          id: "leaderboard_generation",
          rowspan: 2,
          colspan: 1,
          position: { row: 1, col: 4 },
        }, {
          id: "area_health",
          rowspan: 1,
          colspan: 3,
          position: { row: 2, col: 1 },
        }],
      },
    };

    this.setDashboards([
      revenueDashboard,
      engagementDashboard,
    ]);

    this.setIsLoading(false);
  }
}

export default DashboardsStore;
